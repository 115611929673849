import React, { useEffect, useState } from 'react'
import { Chart } from './Chart';
import { ToastContainer, toast } from "react-toastify";

const AssetsTable1 = () => {
    const [currencies, setcurrencies] = React.useState<any[]>([]);
    const [modalValues, setModalValues] = React.useState({
        active: false,
        coin: {
            name: "",
            cryptoLogo: "",
            compliance: "",
            reason: ""
        }
    });

    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(currencies.length / 10);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length > 0) setCurrentPage(1);
        setSearchQuery(prev => e.target.value);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    // Function to get current items
    const getCurrentItems = () => {

        const sortedOnStakingCurrencies = currencies.sort((a: any, b: any) => {
            if (a.stakingEnabled !== b.stakingEnabled) {
                return b.stakingEnabled - a.stakingEnabled
            }

            return b.marketCap - a.marketCap;
        })

        const sortedCurrencies = sortedOnStakingCurrencies.sort((a: any, b: any) => {
            const aMatches = a.chainName.toLowerCase().includes(searchQuery.toLowerCase()) || a.tokenSymbol.toLowerCase().includes(searchQuery.toLowerCase());
            const bMatches = b.chainName.toLowerCase().includes(searchQuery.toLowerCase()) || b.tokenSymbol.toLowerCase().includes(searchQuery.toLowerCase());

            if (aMatches && !bMatches) return -1;
            if (!aMatches && bMatches) return 1;
            return 0;
        });

        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 10;

        return sortedCurrencies.slice(startIndex, endIndex);

    };

    const fetchData = async () => {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}api/chain/getEnabledChains`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = await res.json();
        if (res.ok) {
            const list = data.data
            setcurrencies(list)
        } else {
            console.log("Failed to fetch");
        }
    }

    React.useEffect(() => {
        fetchData()
        let id = setInterval(fetchData, 60000)

        return () => {
            clearInterval(id);
        }
    }, [])


    useEffect(() => {
        const intervalId = setInterval(() => {
            setcurrencies((prevCoins) =>
                prevCoins.map((coin) => {
                    const randomChange = (Math.random() * 0.02) - 0.01; // Random change between -2% and +2%
                    const newPrice = coin.price * (1 + randomChange);
                    // const roundedPrice = Math.round(newPrice * 100) / 100; // Round to two decimal places
                    return {
                        ...coin,
                        price: newPrice,
                        randomChange: randomChange, // Update change for display
                    };
                })
            );
        }, 3000); // Update prices every 2 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [currencies]); // Only run effect when coins change


    function handleComplianceClick(symbol: string, staking: boolean = false) {
        var cryptoCopy: any = currencies;
        cryptoCopy = cryptoCopy.filter((crypto: any) => crypto.tokenSymbol === symbol);
        setModalValues({
            ...modalValues, active: true, coin: {
                name: cryptoCopy[0].chainName,
                cryptoLogo: cryptoCopy[0].logo,
                compliance: staking ? cryptoCopy[0].compliance : cryptoCopy[0].tradingCompliance,
                reason: staking ? cryptoCopy[0].description : cryptoCopy[0].tradingDescription
            }
        });
    };

    function closeModal() {
        setModalValues({
            ...modalValues, active: false
        });
    }


    const notifySuccess = () =>
        toast.info("Coming Soon", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    // const onLoginClick = () => {
    //     const url = `https://app.ethicalnode.com/`;

    //     if (url === undefined) {
    //         notifySuccess();
    //     } else {
    //         debugger;
    //         if (window.top) {
    //             window.top.location.href = url;
    //         }
    //     }
    // };


    // CHART 


    // const daysAgo7 = new Date().getTime() - (864e5 * 7)
    let charData: any = []

    if(currencies.length === 0) {
        return <TableSkeleton/>
    }


    return (
        ((process.env.REACT_APP_SERVER_URL) !== "" && currencies.length > 0) ? (
            <section className='max-w-screen-xl ml-auto mr-auto' >
                {/* <h2 className='section-title align-center bold'>Screened list of proof-of-stake assets</h2>
                <h5 className='section-name align-center'>AS PER SHARIAH POLICY</h5> */}
                {/* <div className='table'> */}
                <table className={` crypto-assets-table min-w-[97%] ${!!modalValues.active && ' blur '}  `} style={{ marginTop: "15px" }}>
                    <thead>
                        <tr>
                            <th className='sticky left-0' colSpan={5}>
                                <div className="search-bar w-52 sm:w-96">
                                    <input
                                        type="text"
                                        placeholder="Search chain..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </th>
                            <th colSpan={2}>Shariah Compliance</th>
                            <th></th>
                        </tr>
                        <tr className="crypto-assets-title-bar">
                            <th className='sticky-rank'>Blockchain</th>
                            <th>Price</th>
                            <th>24h Change</th>
                            {/* <th>24h Low</th> */}
                            {/* <th>24h High</th> */}
                            <th>Market Cap</th>
                            <th>APY</th>
                            {/* <th>Chart</th> */}
                            <th>Trading</th>
                            <th>Staking</th>
                            <th>Stake</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currencies &&
                            getCurrentItems().map((crypto: any) => {
                                charData = []

                                return (
                                    <tr key={crypto.tokenSymbol}>

                                        <Td className="sticky-rank">
                                            <div className="flex items-center justify-left min-w-[125px]">
                                                <img className="asset-image w-7 mr-3" src={crypto.logo} alt={crypto.chainName} />
                                                <div className="text-left">
                                                    <h6 className=" m-0 text-left text-xs md:text-sm ">{crypto.chainName}</h6>
                                                    <p className=" m-0">{String(crypto.tokenSymbol).toUpperCase()}</p>
                                                </div>
                                            </div>
                                        </Td>
                                        <Td><span style={{
                                            textWrap: 'nowrap',
                                            padding: `1px`, borderRadius: "2px",
                                            backgroundColor: crypto?.randomChange > 0 ? 'green' : 'red',
                                        }}>{"$ " + Number(crypto.price).toFixed(6)}</span></Td>
                                        <Td className={String(crypto.Change_24h).startsWith("-") ? "red-color" : "green-color"}>
                                            {Number(crypto.Change_24h).toFixed(2) + "%"}
                                        </Td>
                                        {/* <td>{"$" + Number(crypto.Low_24h).toFixed(6)}</td> */}
                                        {/* <td>{"$" + Number(crypto.High_24h).toFixed(6)}</td> */}
                                        <Td className='text-nowrap'>{"$" + Number(crypto.marketCap).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Td>
                                        <Td className={String(crypto.apy).startsWith("-") ? "red-color" : "green-color"}>{(crypto.apy).toFixed(2) + "%"}</Td>
                                        {/* {
                                            crypto?.chart?.map((item: any, i: number) => {
                                                charData.push({
                                                    time: daysAgo7 + (i * 36e5),
                                                    value: item
                                                })
                                                return null
                                            })
                                        } */}
                                        {/* <td>
                                            <Chart data={charData} lineColor={String(crypto.Change_24h).startsWith("-") ? "#F03F3E" : "#1EC067"} />
                                        </td> */}
                                        <Td>
                                            <button style={{ background: "transparent" }} onClick={() => handleComplianceClick(`${crypto.tokenSymbol}`)} className={(crypto.tradingCompliance === 0) ? "green-color td-css hover" : (crypto.tradingCompliance === 2) ? " yellow-color td-css hover " : " red-color td-css hover "}>
                                                {crypto.tradingCompliance === 0 ? "Comfortable" : crypto.tradingCompliance === 2 ? "Questionable" : "Non-Comfortable"}
                                            </button>
                                        </Td>
                                        <Td>
                                            <button style={{ background: "transparent" }} onClick={() => handleComplianceClick(`${crypto.tokenSymbol}`, true)} className={(crypto.compliance === 0) ? "green-color td-css hover" : (crypto.compliance === 2) ? "yellow-color td-css hover" : "red-color td-css hover"}>
                                                {crypto.compliance === 0 ? "Comfortable" : crypto.compliance === 2 ? "Questionable" : "Non-Comfortable"}
                                            </button>
                                        </Td>
                                        <Td>
                                            {
                                                crypto.stakingEnabled ?
                                                    <a target='_parent' href={`https://app.ethicalnode.com/`} className='px-3 py-2 rounded-md bg-[#E946DA] hover:bg-[#9625e0]' style={{ filter: crypto.stakingEnabled ? `grayscale(0)` : `grayscale(1)`, cursor: crypto.stakingEnabled ? `pointer` : `not-allowed` }}  >Stake</a>
                                                    :
                                                    <button className='px-3 py-2 rounded-md bg-[#E946DA] hover:bg-[#9625e0]' style={{ filter: crypto.stakingEnabled ? `grayscale(0)` : `grayscale(1)`, cursor: crypto.stakingEnabled ? `pointer` : `not-allowed` }} disabled={crypto.stakingEnabled ? false : true} >Stake</button>
                                            }
                                        </Td>
                                    </tr>
                                )
                            }

                            )
                        }


                        <tr><td colSpan={10} style={{ padding: "10px 0px" }}>
                            {/* {[...Array(Math.ceil(currencies.length / 10))].map((num: any, index) => {
                                return <button className={'button-app'} style={{ fontSize: "14px", margin: "1px 4px", padding: "6px 10px", borderRadius: "4px" }}> {index + 1}</button>
                            })} */}
                            {/* PREVIOUS BUTTON */}
                            <button
                                className={currentPage > 1 ? `button-app`: ''}
                                style={{ fontSize: "14px", margin: "2px 4px", padding: "6px 10px", borderRadius: "4px", 
                                    cursor: currentPage <= 1 ? "not-allowed" : "pointer",
                                    backgroundColor: currentPage <= 1 ? "#ccc" : "#007bff", // Gray when disabled, blue when enabled
                                    color: currentPage <= 1 ? "#666" : "#fff"// Darker gray for disabled text
                                 }}
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                🡠 Prev
                            </button>
                            {/* Dynamic Page Numbers */}
                            {Array.from({ length: 3 }, (_, index) => {
                                // Calculate the page number to display
                                const startPage = Math.max(1, Math.min(currentPage - 1, totalPages - 2));
                                const pageNumber = startPage + index;
                                if (pageNumber > totalPages) return null; // Don't render buttons beyond total pages

                                return (
                                    <button
                                        className={`${currentPage === pageNumber ? `button-selected` : `button-app`} `}
                                        style={{ fontSize: "14px", margin: "2px 4px", padding: "6px 10px", borderRadius: "4px", cursor: "pointer" }}
                                        key={pageNumber}
                                        onClick={() => handlePageChange(pageNumber)}
                                        disabled={currentPage === pageNumber}
                                    >
                                        {pageNumber}
                                    </button>
                                );
                            })}

                            {/* Next Button */}
                            <button
                                className={currentPage !== totalPages ? `button-app`: ''}
                                style={{ fontSize: "14px", margin: "2px 4px", padding: "6px 10px", borderRadius: "4px",
                                    cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                                    backgroundColor: currentPage === totalPages ? "#ccc" : "#007bff", // Gray when disabled, blue when enabled
                                    color: currentPage === totalPages ? "#666" : "#fff" // Darker gray for disabled text
                               
                                 }}
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next 🡢
                            </button>
                            

                        </td></tr>
                    </tbody>
                </table>

                {modalValues.active && <>
                    <div className={modalValues.active ? ` modalBg` : `none`} onClick={closeModal}></div>
                    <section className='modaldesign'>
                        <div className='children' id='table_children'>
                            <button className='close-button' onClick={closeModal}>X</button>
                            <div className='coin-info'>
                                <img className='coin-image' src={modalValues.coin.cryptoLogo} alt={modalValues.coin.name} />
                                <div>
                                    <h3>{modalValues.coin.name}</h3>
                                    <p className={(Number(modalValues.coin.compliance) === 0) ? "green-color" : (Number(modalValues.coin.compliance) === 2) ? "yellow-color" : "red-color"}>
                                        <span id="coin-compliance">{Number(modalValues.coin.compliance) === 0 ? "Shariah Compliant" : Number(modalValues.coin.compliance) === 2 ? "Questionable" : "Non-Shariah Compliant"}</span>
                                    </p>
                                </div>
                            </div>
                            {modalValues.coin.reason && <HtmlNewlineRenderer content={modalValues.coin.reason} />}
                            {/* <p className="coin-reason">{modalValues.coin.reason}</p> */}
                        </div>
                    </section>
                </>}
                {/* </div> */}
                <ToastContainer />
            </section>
        ) : null



    )
}

export default AssetsTable1


interface TdProps {
    className?: string
    children: any
}

const Td: React.FC<TdProps> = ({ className, children }) => {

    return <td className={`text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs ${className}`}>{children}</td>

}


const HtmlNewlineRenderer: React.FC<any> = ({ content }) => {
    // Split the content by newline characters
    const lines = content.split(/\n/);

    return (
        <div className="relative w-full max-w-full mx-auto">
            {/* Outer shadow to create depth effect */}
            {/* <div className="absolute border-red-400 border-2 inset-0 bg-gray-800 rounded-md shadow-[inset_0_1px_2px_rgba(255,255,255,0.1)]"></div> */}

            {/* Inset content area */}
            <div className="relative inset-0  whitespace-pre-wrap w-full rounded-md coin-reason max-h-[350px] overflow-y-auto p-4 bg-gray-900 shadow-[inset_0_2px_4px_rgba(0,0,0,0.5)]">
                <div className="text-gray-300">
                    {lines.map((line: any, index: any) => (
                        <React.Fragment key={index}>
                            {index > 0 && <br />}
                            <p dangerouslySetInnerHTML={{ __html: line }} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};





const TableSkeleton = () => {
  // Create an array of 10 items to represent rows in the skeleton
  const rows = Array(10).fill(0);

  return (
    <section className='max-w-screen-xl ml-auto mr-auto'>
      <table className="crypto-assets-table min-w-[97%]" style={{ marginTop: "15px" }}>
        <thead>
          <tr>
            <th className='sticky left-0' colSpan={5}>
              <div className="search-bar w-52 sm:w-96">
                {/* Skeleton for search input */}
                <div className="h-10 w-full bg-gray-600 rounded animate-pulse"></div>
              </div>
            </th>
            <th colSpan={2}>Shariah Compliance</th>
            <th></th>
          </tr>
          <tr className="crypto-assets-title-bar">
            <th className='sticky-rank'>Blockchain</th>
            <th>Price</th>
            <th>24h Change</th>
            <th>Market Cap</th>
            <th>APY</th>
            <th>Trading</th>
            <th>Staking</th>
            <th>Stake</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((_, index) => (
            <tr key={index}>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs sticky-rank">
                <div className="flex items-center justify-left min-w-[125px]">
                  {/* Skeleton for coin logo */}
                  <div className="w-7 h-7 mr-3 rounded-full bg-gray-600 animate-pulse"></div>
                  <div className="text-left">
                    {/* Skeleton for coin name */}
                    <div className="h-4 w-24 bg-gray-600 rounded animate-pulse mb-1"></div>
                    {/* Skeleton for coin symbol */}
                    <div className="h-3 w-12 bg-gray-600 rounded animate-pulse"></div>
                  </div>
                </div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-20 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-16 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-24 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-16 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-24 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-6 w-24 bg-gray-600 rounded animate-pulse mx-auto"></div>
              </td>
              <td className="text-center py-[0.6rem] px-6 !text-[0.6rem] sm:!text-[0.8rem] lg:!text-xs">
                <div className="h-8 w-16 bg-gray-600 rounded-md animate-pulse mx-auto"></div>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={10} style={{ padding: "10px 0px" }}>
              {/* Skeleton for pagination */}
              <div className="flex justify-center space-x-2">
                <div className="h-8 w-16 bg-gray-600 rounded animate-pulse"></div>
                <div className="h-8 w-10 bg-gray-600 rounded animate-pulse"></div>
                <div className="h-8 w-10 bg-gray-600 rounded animate-pulse"></div>
                <div className="h-8 w-10 bg-gray-600 rounded animate-pulse"></div>
                <div className="h-8 w-16 bg-gray-600 rounded animate-pulse"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

